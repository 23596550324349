import { authRequest } from "../../../../utils/request/authRequest";

interface ReturnData {
  status: string;
}

export const deleteComment = async (postId: string, commentId: string): Promise<ReturnData> => {
  const response = await authRequest(`v2/posts/${postId}/comments/${commentId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": `application/json`,
    },
  });
  return response.data;
};
