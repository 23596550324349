import { authRequest } from "../../../../utils/request/authRequest";

interface ReturnData {
  status: string;
}

export const deleteReaction = async (postId: string): Promise<ReturnData> => {
  const response = await authRequest(`v2/posts/${postId}/reactions`, {
    method: "DELETE",
    headers: {
      "Content-Type": `application/json`,
    },
  });
  return response.data;
};
