import { authRequest } from "../../../../utils/request/authRequest";

interface ReturnData {
  status: string;
}

export interface CommentData {
  content: string;
  parentId: string | null;
}

export const postComment = async (postId: string, commentData: CommentData): Promise<ReturnData> => {
    const response = await authRequest(
        `v2/posts/${postId}/comments`, {
            method: "POST",
            headers: {
                "Content-Type": `application/json`,
            },
        },
        commentData,
    );
  return response.data;
};
