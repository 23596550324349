import { authRequest } from "../../../../utils/request/authRequest";
import { Author, Meta } from "../../getFeed.query";

export interface CommentResponse {
    data: CommentData[];
    meta: Meta;
}

export interface CommentData {
    error?: any;
    id: string;
    content: string;
    createdAt: string;
    threadId: string;
    parentId: string;
    author: Author;
    childrenCount: number;
}

export const getComments = async (postId?: string, parentId?: string, page?: number, limit?: number, commentIds?: string[]) => {
    const params = new URLSearchParams();

    if (parentId) params.append('parentId', parentId);
    if (page) params.append('page', page.toString());
    if (limit) params.append('limit', limit.toString());
    if (commentIds && commentIds.length > 0) commentIds.forEach(id => params.append('commentsIds', id));

    const url = `v2/posts/${postId}/comments?${params.toString()}`;

    const response = await authRequest(
        url, {
            method: 'GET',
            headers: {
                'Content-Type': `application/json`,
            },
        }
    )
    return response.data;
}
