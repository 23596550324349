import { authRequest } from "../../../utils/request/authRequest";
import { Author, Meta } from "../getFeed.query";

export interface PostData {
    id: string;
    type: string;
    reactions: any[];
    title: string | null;
    author: Author;
    commentsMetadata: { count: number },
    reactionsMetadata: { likeCount: number, dislikeCount: number }
    content: string;
    createdAt: string;
    resourceUrl: string | null;
    threadId: string;
    liked: boolean;
    reported: boolean;
}

export interface PostResponse {
    error?: any;
    data: PostData[];
    meta: Meta;
}

export const getPosts = async (page?: number, limit?: number, usersIds?: string[], id?: string[]): Promise<PostResponse> => {
    const params = new URLSearchParams();

    if (id && id.length > 0) id.forEach(id => params.append('id', id));
    if (page) params.append('page', page.toString());
    if (limit) params.append('limit', limit.toString());
    if (usersIds && usersIds.length > 0) usersIds.forEach(id => params.append('usersIds', id));

    const url = `v2/posts?${params.toString()}`;

    const response = await authRequest(
        url, {
            method: 'GET',
            headers: {
                'Content-Type': `application/json`,
            },
        }
    )
    return response.data
}
