import { authRequest } from "../../../../utils/request/authRequest";

interface ReturnData {
  id: string;
}

export const postReaction = async (postId: string, type: string): Promise<ReturnData> => {
    const response = await authRequest(
        `v2/posts/${postId}/reactions`, {
            method: "POST",
            headers: {
                "Content-Type": `application/json`,
            },
        },
        { type: type },
    );
  return response.data;
};
