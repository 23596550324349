import React, { FC, useEffect, useState } from "react";
import { deleteComment } from "../../../../queries/feed/posts/comments/deletePostComment.query";
import { deleteQuestComment } from "../../../../queries/feed/quests/comments/deleteQuestComment.query";
import { CommentData } from "../../../../queries/feed/posts/comments/getPostComments.query";
import { useMutation } from "react-query";
import { CircularProgress, Menu, MenuItem, MenuProps, Typography, styled } from "@mui/material";
import { ErrorDto } from "../../../../types/error.dto";
import useUserData from "../../../../hooks/useUserData";
import toast from "react-hot-toast";
import Linkify from 'react-linkify';
import { formattedDate } from "./Post";
import { useRouter } from "next/router";
import { Avatar } from "@/components/atoms";
import { CreateComment } from "./CreateComment";
import { getComments, CommentResponse } from "../../../../queries/feed/posts/comments/getPostComments.query";
import { getQuestComments } from "../../../../queries/feed/quests/comments/getQuestComments.query";
import { useSearchParams } from "next/navigation";

interface CommentProps {
  questId?: string;
  postId?: string;
  comment: CommentData;
  refreshComments: any;
  commentDetail?: boolean;
}

export const Comment: FC<CommentProps> = ({ postId, questId, comment, refreshComments, commentDetail }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const searchParams = useSearchParams();
  const userData = useUserData();
  const [deleted, setDeleted] = useState(false);
  const router = useRouter();
  const [comments, setComments] = useState<CommentResponse | null>(null);
  const [commentsPage, setCommentsPage] = useState(0);
  const commentId = searchParams?.get("commentId");
  
  const getCommentsMut = useMutation(({ postId, parentId, page, limit }: { postId?: string, parentId?: string, page?: number, limit?: number }) => getComments(postId, parentId, page, limit), {
    onSuccess: (data) => {
      // console.log(data);
      if(data.error){
        let timeoutId;
        timeoutId = setTimeout(() => {
          setComments(null);
        }, 500);
        clearTimeout(timeoutId);
      } else {
        setComments((prevComments) => ({
          data: [...(prevComments?.data || []), ...data.data],
          meta: data.meta,
        }));
      }
    },
    onError: (e: ErrorDto) => {
      console.error("Error fetching comments", e);
    },
  });

  const getQuestCommentsMut = useMutation(({ questId, parentId, page, limit }: { questId?: string, parentId?: string, page?: number, limit?: number }) => getQuestComments(questId, parentId, page, limit), {
    onSuccess: (data) => {
      // console.log(data);
      if(data.error){
        let timeoutId;
        timeoutId = setTimeout(() => {
          setComments(null);
        }, 500);
        clearTimeout(timeoutId);
      } else {
        setComments((prevComments) => ({
          data: [...(prevComments?.data || []), ...data.data],
          meta: data.meta,
        }));
      }
    },
    onError: (e: ErrorDto) => {
      console.error("Error fetching comments", e);
    },
  });

  const openCommentDetails = () => {
    if(questId){
      router.push(`/feed/comment?questId=${questId}&commentId=${comment.id}`);
    }
    if(postId){
      router.push(`/feed/comment?postId=${postId}&commentId=${comment.id}`);
    }
  }

  const deleteCommentMut = useMutation(({ postId, commentId }: { postId: string, commentId: string }) => deleteComment(postId, commentId), {
    onSuccess: async () => {
      commentDetail ? router.back() : refreshComments();
    },
    onError: (e: ErrorDto) => {
      toast.error("Error deleting post: " + e);
    },
  });

  const deleteQuestCommentMut = useMutation(({ questId, commentId }: { questId: string, commentId: string }) => deleteQuestComment(questId, commentId), {
    onSuccess: async () => {
      commentDetail ? router.back() : refreshComments();
    },
    onError: (e: ErrorDto) => {
      toast.error("Error deleting post: " + e);
    },
  });

  const handleDeleteComment = (commentId: string) => {
    if(questId){
      deleteQuestCommentMut.mutate({ questId: questId, commentId: commentId });
    }
    if(postId){
      deleteCommentMut.mutate({ postId: postId, commentId: commentId });
    }
    close();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (commentsPage > 0 && commentDetail) {
      if(postId) getCommentsMut.mutate({ postId: postId, parentId: commentId || comment.id, page: commentsPage });
      if(questId) getQuestCommentsMut.mutate({ questId: questId, parentId: commentId || comment.id, page: commentsPage });
    }
  }, [commentsPage, comment]);

  useEffect(() => {
    setCommentsPage(1);
  }, [])

  return deleted || !comment ? (
    <div className="flex justify-center mb-2">
      <CircularProgress size={16} style={{ color: '#8da2fb', margin: 'auto' }} />
    </div>
  ) : (
    <>
    <div className="flex row">
      <div className="column flex p-2 pt-1 pb-0">
        <a href={`/@${comment.author.username}`}>
          <Avatar
            size="small" color="indigo" rounded className="shrink-0 ml-4 mr-1"
            avatarUrl={comment.author.avatarUrl}
            username={comment.author.username}
          />
        </a>
        <div className="flex-row ml-2 flex-grow mb-2">
            <div className="column flex justify-between mr-4 relative">
                <a href={`/@${comment.author.username}`} className="mr-16"> 
                  <span className="text-gray-700 font-semibold text-[14px]">
                      {comment.author.firstName} {comment.author.lastName}
                      {/* <Verified style={{ width: "16px", color: "#5850EC", marginLeft: "4px" }}/> */}
                  </span>
                  <span className="text-gray-500 text-[12px] ml-1">
                      @{comment.author.username}
                  </span>
                </a>
                <div className="flex column absolute right-0 top-1">
                  <span className="text-gray-500 text-[12px] mr-2">{formattedDate(comment.createdAt)}</span>
                  <button onClick={handleClick}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.66663 7.99967C2.66663 8.17649 2.73686 8.34605 2.86189 8.47108C2.98691 8.5961 3.15648 8.66634 3.33329 8.66634C3.5101 8.66634 3.67967 8.5961 3.8047 8.47108C3.92972 8.34605 3.99996 8.17649 3.99996 7.99967C3.99996 7.82286 3.92972 7.65329 3.8047 7.52827C3.67967 7.40325 3.5101 7.33301 3.33329 7.33301C3.15648 7.33301 2.98691 7.40325 2.86189 7.52827C2.73686 7.65329 2.66663 7.82286 2.66663 7.99967Z" stroke="#4B5563" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7.33329 7.99967C7.33329 8.17649 7.40353 8.34605 7.52855 8.47108C7.65358 8.5961 7.82315 8.66634 7.99996 8.66634C8.17677 8.66634 8.34634 8.5961 8.47136 8.47108C8.59639 8.34605 8.66663 8.17649 8.66663 7.99967C8.66663 7.82286 8.59639 7.65329 8.47136 7.52827C8.34634 7.40325 8.17677 7.33301 7.99996 7.33301C7.82315 7.33301 7.65358 7.40325 7.52855 7.52827C7.40353 7.65329 7.33329 7.82286 7.33329 7.99967Z" stroke="#4B5563" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 7.99967C12 8.17649 12.0702 8.34605 12.1952 8.47108C12.3202 8.5961 12.4898 8.66634 12.6666 8.66634C12.8434 8.66634 13.013 8.5961 13.138 8.47108C13.2631 8.34605 13.3333 8.17649 13.3333 7.99967C13.3333 7.82286 13.2631 7.65329 13.138 7.52827C13.013 7.40325 12.8434 7.33301 12.6666 7.33301C12.4898 7.33301 12.3202 7.40325 12.1952 7.52827C12.0702 7.65329 12 7.82286 12 7.99967Z" stroke="#4B5563" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </button>
                </div>
            </div>
            <div className="pr-4">
            <Typography
              style={{
                color: "#1F2937",
                fontSize: "12px",
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}  
            >
              <Linkify componentDecorator={(href: any, text: any, key: any) => (<a href={href} key={key} style={{ color: '#5850ec' }}>{text}</a>)}>
                {comment.content}
              </Linkify>
            </Typography>
            </div>
          </div>
        </div>
          <div className="pl-[68px] pr-8 pb-2">
            <div className="flex column w-auto min-w-[40px] gap-1 pt-0 m-0">
              <button onClick={() => {openCommentDetails()}}>
                <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 14.9997H5C4.33696 14.9997 3.70107 14.7363 3.23223 14.2674C2.76339 13.7986 2.5 13.1627 2.5 12.4997V5.83301C2.5 5.16997 2.76339 4.53408 3.23223 4.06524C3.70107 3.5964 4.33696 3.33301 5 3.33301H15C15.663 3.33301 16.2989 3.5964 16.7678 4.06524C17.2366 4.53408 17.5 5.16997 17.5 5.83301V12.4997C17.5 13.1627 17.2366 13.7986 16.7678 14.2674C16.2989 14.7363 15.663 14.9997 15 14.9997H12.5L10 17.4997L7.5 14.9997Z" stroke="#6B7280" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
              <span className={`text-[14px] mt-[2px] text-gray-500`}>{comment.childrenCount}</span>
            </div>
          </div>
      </div>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {(userData?.id === comment.author.id || userData?.roles.includes("ADMIN")) && (
          <MenuItem
            onClick={() => {
              handleDeleteComment(comment.id);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#6B7280"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon icon-tabler icons-tabler-outline icon-tabler-trash mr-2"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M4 7l16 0" />
              <path d="M10 11l0 6" />
              <path d="M14 11l0 6" />
              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>
            <p className="py-1 pr-4 text-base text-gray1">Delete</p>
          </MenuItem>
        )}
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    },
  }),
);
