import React, { FC, useState } from "react";
import useUserData from "../../../../hooks/useUserData";
import TextField from "@mui/material/TextField";
import { Avatar } from "@/components/atoms";
import { CircularProgress } from "@mui/material";

interface Props {
  questId?: string;
  postId?: string;
  parentId: string | null;
  uploadCommentMut: any;
}

export const CreateComment: FC<Props> = ({ postId, questId, parentId, uploadCommentMut }) => {
  const [content, setContent] = useState("");
  const [disabled, setDisabled] = useState(true);
  const userData = useUserData();

  const handleCreateComment = () => {
    const commentData = {
      content: content,
      parentId: parentId,
    };
    setContent("");
    if(postId) uploadCommentMut.mutate({ postId: postId, commentData: commentData});
    if(questId) uploadCommentMut.mutate({ questId: questId, commentData: commentData});
  };

  const handleCommentChange = (value: string) => {
    value.length > 1000
      ? setContent(value.substring(0, 1000))
      : setContent(value);
    setDisabled(value.length < 1);
  };

  return (
    <div className={`flex flex-row ${content.length > 0 && "pb-0"}`}>
      <Avatar
        size="small"
        color="indigo"
        rounded
        className={`ml-[-4px] mr-2 shrink-0 `}
        avatarUrl={userData?.profile.avatarUrl}
        username={userData?.username}
      />

      <div className="row flex w-full pl-4 pr-4">
        <TextField
          onChange={(e: any) => handleCommentChange(e.target.value)}
          value={content}
          placeholder="Post your reply"
          variant="standard"
          fullWidth
          multiline
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: "14px",
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              padding: "5px",
              backgroundColor: "#F9FAFB",
            },
          }}
        />
        {content.length > 0 && (
          <div
            className="column mt-1 flex"
            style={{ justifyContent: "flex-end" }}
          >
            <CircularProgress
              variant="determinate"
              value={(content.length / 1000) * 100}
              sx={{ color: "#5850EC", padding: "12px" }}
              className="mt-[-4px]"
            />
            <span className="mt-[8px] text-[12px] text-gray-600">
              {content.length}/1000
            </span>
          </div>
        )}
      </div>
      <button
        className="mainButton ml-2"
        onClick={handleCreateComment}
        disabled={disabled}
        style={{
          height: "32px",
          width: "58px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Reply
      </button>
    </div>
  );
};
